$mainOrange : #FF7800;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
$error : #FE4D97;
$success: rgb(21, 187, 49);

html{
    background-color: #EFF3F6;
}

html, body, #root, .App, .content{
    height: auto;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

p{
    margin: 0;
}

.header-wrap{
    background-color: #fff;
    padding: 5px 20px;
    width: 100%;
    height: 70px;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 1px 0 #E6EAEE;

    .header-inner{
        max-width: 1300px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        flex-direction: row;
        // height: 70px;
        justify-content: space-between;
        // color: #fff;

        .left{
            flex-direction: row;
            display: flex;
            align-items: center;
            .home-link{
                text-decoration:none;
                color: #000;
            }
        }

        .middle {
            position: relative;

            input {
                padding: 10px 15px;
                padding-right: 30px;
                border: 1px solid #DFE3E9;
                outline: none;
                width: 200px;
                border-radius: 4px;
                font-size: 14px;
                font-family: "Source Sans Pro";
                font-weight: 400;
                // background-image: url(../images/search.png);
                // background-size: 15px;
            }

            img {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .right{
            flex-direction: row;
            display: flex;
            align-items: center;
            cursor: pointer;
            
            img{
                width: 40px;
                height: 40px;
                border-radius: 20px;
                margin-right: 15px;
            }

            .dropdown-nav {
                display: flex;
                align-items: center;
            }
            
            .profile-dropdown-wrap{
                position: relative;
                .profile-dropdown{
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                    position: absolute;
                    color: #000;
                    z-index: 10;
                    background-color: #fff;
                    width: 150px;
                    right: 0;
                    top: 45px;
                    display: flex;
                    flex-direction: column;

                    // &:before {
                    //     position: absolute;
                    //     right: 15px;
                    //     bottom: 100%;
                    //     content: '';
                    //     width: 0; 
                    //     height: 0; 
                    //     border-left: 5px solid transparent;
                    //     border-right: 5px solid transparent;
                        
                    //     border-bottom: 5px solid #fff;
                    // }

                    .dropdown-link{
                        box-sizing: border-box;
                        padding: 10px;
                        width: 100%;
                        text-decoration: none;
                        color: #000;
                        text-align: left;
                        border: none;
                        font-weight: 600;
                        font-size: 16px;
                        background: #fff;
                        &:hover{
                            background-color: $mainOrange;
                            color: #fff;
                        }

                        &.border-top{
                            border-top: 1px solid #E6EAEE;
                        }
                    }
                }
            }
        }
    }
}

.auth-superwrap{
    // height: 100vh;
    // background-image: url('../images/background-1.jpg');
    // background-repeat: no-repeat;
    // background-position: center center;
    // background-size: cover;
    background-image: linear-gradient(to left, #414141 0%, #252526 100%);
    height: 100vh;
    width: 100vw;
    // justify-content: center;
    // display: flex;
    // align-items: center;
    // position: relative;

    .phone-label{
        text-align: left;
        color: #fff;
        padding: 5px;
    }

    .phone-submit{
        padding: 5px;
        margin-top: 20px;
    }

    .resend-wrap{
        color: #fff;
        margin-top: 20px;

        .yellow-highlight{
            color: #F9CE49;
        }

        .resend{
            margin-top: 30px;
            span{
                cursor: pointer;
            }
        }
    }

    .auth-backdrop {
        // background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .auth-wrap{
        z-index: 100;
        // padding: 20px;
        max-width: 450px;
        width: 100%;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;

        .logo-row{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom:50px;
            .logo-wrap{
                // background-color: #fff;
                // border-radius: 50%;
                // width: 70px;
                // height: 70px;
                // box-sizing: border-box;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // margin-right: 15px;

                img{
                    height: 80px;
                }
            }
            .logo-title{
                font-size: 30px;
                color: #000;
                font-weight: bold;
                font-family: "Nunito Sans";
            }
        }
        
        .auth-wrap-inner{
            padding: 40px;
            // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            border: 1px solid #E6EAEE;
            color: #000;
            border-radius: 10px;
            // background: linear-gradient(137deg, rgba(102,102,102,1) 0%, rgba(81,81,81,1) 50%, rgba(102,102,102,1) 100%);
            background-color: #fff;

            .input-holder {
                padding: 0;
                margin-bottom: 20px;
            }

            .form-wrapper {
                margin-top: 10px;
            }

            .btn-holder {
                margin-top: 30px;
                margin-bottom: 20px;
            }

            .auth-title{
                margin-bottom: 15px;
                h1{
                    color: $mainOrange;
                }
        
                p{
                    font-weight: bold;
                    font-size: 20px;
                    // color: white;
                    // color: rgb(130, 130, 130);
                }
            }
        }

        // .form-wrapper{
        //     .input-holder{
        //         input{
        //             &:not([type="submit"]){
        //                 background-color: #bbb;
        //             }
        //         }
        //     }
        // }
    
        .link-separator{
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            .line{
                width: 30px;
                height: 0;
                border: 1px solid white;
            }
    
            p{
                z-index: 10;
                display: inline-block;
                padding: 0px 10px;
                font-weight: bold;
            }
        }
    
        .social-login-wrapper{
            display: flex;
            flex-direction: column;
            // margin: 15px 0;
            margin-top: 20px;

            .button-holder{
                display: flex;
                flex-direction: row;
                flex: 1;
                // padding: 0 15px;
                .social-login-button{
                    color: #000;
                    padding: 15px;
                    display: flex;
                    width: 100%;
                    border: none;
                    font-family: "Source Sans Pro";
                    justify-content: center;
                    border-radius: 5px;
                    font-size: 16px;
                    // margin: 10px 0;
                    margin-bottom: 10px;
                    cursor: pointer;
                    background-color: #f1f1f1;
                    font-weight: bold;
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }
            
    
            .fb-login{
                // background-color: #3b5998;
            }
    
            .google-login{
                // background-color: #FF4A0D;
            }
        }

        .switch {
            margin-top: 20px;
        }
    
        .switch-link{
            color: $mainOrange;
            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
        .forgot-password{
            margin-top: 10px;
            color: $mainOrange;
            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.popup-backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    box-sizing: border-box;
    top: 0;
    left: 0;
    .popup{
        background: #fff;
        width: 100%;
        max-width: 1100px;
        padding: 20px;
        border-radius: 5px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        

        .close-popup{
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            img{
                width: 20px;
                height: 20px;
            }
        }

        &.small {
            max-width: 800px;
        }
    }
}

.form-wrapper{

    .stand-in-label{
        text-align: left;
        font-size: 18px;
        margin-bottom: 5px;
        color: #7F8FA4;
    }
    .yellow-btn{
        background-color: #FCB21F;
        color: #fff;
        border-radius: 4px;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;
    }

    button {
        margin-top: 30px;
        width: 100%;
        background: #FF7800;
        padding: 10px;
        color: #fff;
        font-size: 15px;
        border: none;
        outline: none;
        font-weight: 600;
        border-radius: 2px;
        cursor: pointer;
    }

    .orange-btn{
        background-color: #FF7800;
        color: #fff;
        border-radius: 4px;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;
        margin-top: 15px;
    }

    .input-holder{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px;
        box-sizing: border-box;
        margin-bottom: 10px;
        position: relative;

        &.half{
            width: 50%;
        }

        &.quarter{
            width: 25%;
        }

        &.password{
            label{
                margin-bottom: 0px;
            }

            input{
                position: relative;
                padding-right: 30px;
            }

            img{
                width: 20px;
                height: 20px;
                position: absolute;
                right: 25px;
                cursor: pointer;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.data-display{
            input{
                background-color: #FFF2E5;
                border: 1px solid #FF7800;
            }
        }

        .top-right{
            position: absolute;
            top: 15px;
            right: 15px;
            color: #ff7800;
            font-size: 14px;
        }
        
        a{
            width: 100%;
        }

        label{
            width: 100%;
            text-align: left;
            font-size: 18px;
            margin-bottom: 5px;
            color: #7F8FA4;
        }

        textarea {
            font-family: "Source Sans Pro";
            width: 100%;
            font-size: 18px;
            padding: 10px 15px;
            border: 1px solid #E6EAEE;
            border-radius: 5px;
            resize: none;
        }

        input{
            font-family: "Source Sans Pro";
            width: 100%;
            padding: 10px 15px;
            font-size: 18px;
            border-radius: 5px;
            border: 1px solid #E6EAEE;
        
            &[type=submit]{
                border: none;
                background-color: $mainOrange;
                color: white;
                font-weight: bold;
                cursor: pointer;
            }
        }

        select {
            width: 100%;
            padding: 10px 15px;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-position: right 15px center;
            background-size: 15px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+');
            font-family: "Source Sans Pro";
            -webkit-appearance: none;
            appearance: none;
            font-size: 18px;
            border: 1px solid #E6EAEE;
        }

        .select-wrap{
            width: 100%;
            position: relative;
            cursor: pointer;
            select{
                width: 100%;
                padding: 10px 15px;
                padding-right: 20px;
                -webkit-appearance: none;
                background-color: #fff;
                border: 1px solid #DFE3E9;
            }

            img{
                width: 8px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }


    }

    .checkbox-input{
        display: flex;
        width: 100%;
        align-items: center;
        input[type=checkbox]{
            width: auto;
            margin-right: 10px;
        }

        label{
            margin-bottom: 0px;
            cursor: pointer;
        }
    }

    .radio-label{
        text-align: left;
        padding-left: 15px;
        font-size: 18px;
        color: #7F8FA4;
    }
    .radio-select-holder{
        // padding: 10px 15px;
        margin-bottom: 20px;

        .title{
            color: #7F8FA4;
            width: 100%;
            text-align: left;
            margin-bottom: 15px;
        }

        .radio-select{
            display: flex;
            position: relative;

            &.disabled {
                opacity: 0.5;
            }
            
            label{
                border: 1px solid #E6EAEE;
                border-radius: 5px;
                padding: 15px 20px;
                padding-left: 50px;
                width: 100%;
                text-align: left;
                cursor: pointer;
            }

            input[type=radio]{
                position: absolute;
                top: 45%;
                transform: translateY(-50%);
                left: 15px;
            }
            
            &.hide-radio{
                label{
                    padding: 10px 30px;
                }

                input[type=radio]{
                    visibility: hidden;
                    height: 0px;
                    width: 0px;
                }
            }

            &.active{
                label{
                    border: 1px solid #FF7800;
                }
            }
        }


        &.inline{
            display: flex;
            flex-wrap: wrap;
            .radio-select{
                display: flex;
                position: relative;
                margin-right: 20px;
            }
        }
        
    }

    
    .otp {
        input {
            text-align: center;
        }
    }
    
    .side-by-side{
        display: flex;
        flex-direction: row;

        &.phone-format{
            .input-holder{
                padding: 10px 5px;
                &:last-child{
                    flex: 4;
                }
            }
        }

        .input-holder{
            flex: 1;
        }
    }
}

.sidebar{
    // position: absolute;
    // left: 0;
    // top: 53px;
    min-width: 250px;
    height: 100%;
    text-align: left;
    // padding-top: 30px;

    a{
        text-decoration: none;
    }

    .sidebar-title{
        font-size: 14px;
    }
    .link{
        padding: 7.5px 50px;
        padding-left: 0;
        color: #9FA9BA;
        display:flex;
        flex-direction: row;
        cursor: pointer;

        &.active{
            color: #000;
        }

        .sidebar-icon{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}

.table-wrapper{
    width: 100%;
    border: 1px solid #E6EAEE;

    table{
        width: 100%;
        border-collapse: collapse;
        a{
            text-decoration: none;
        }
        thead{
            background-color: #F5F8FA;
            border-bottom: 1px solid #E6EAEE;
            color: #7F8FA4;
            th{
                padding: 15px 15px;
                font-size: 18px;

                &.left-align{
                    text-align: left;
                }

                &.right-align{
                    text-align: right;
                }
            }
        }
        
        tbody{

            tr{
                background-color: #fff;
                border-bottom: 1px solid #E6EAEE;

                &:last-child{
                    border-bottom:0px;
                }

                td{
                    padding: 25px 20px;
                    font-size: 14px;

                    &.left-align{
                        text-align: left;
                    }

                    &.right-align{
                        text-align: right;
                    }
                }
            }
        }
        
    }
}

.payment-data{
    background-color: #fff;

    .title{
        font-weight: bold;
        text-align: left;
        margin-bottom: 20px;
    }

    &.prepaid {

        tr:last-child {

            td {
                padding-top: 5px;
            }
        }

        td {
            padding: 0;

            &.phone-no {
                padding-left: 30px;
                opacity: 0.5;
            }
        }
    }
    .payment-table{
        width: 100%;
        border-collapse: collapse;
    
        .title{
            text-align: left;
        }
        tr{
            td{
                padding:5px;
                &:first-child{
                    text-align: left;
                }
    
                &:last-child{
                    text-align: right;
                }
    
                &.force-left{
                    text-align: left;
                }
            }
    
            &.bold-text{
                font-weight: bold;
            }
    
            &.border-top{
                td{
                    border-top: 1px solid #E1E1E1;
                }
            }
        }
    }
}


.App{
    
    &.push {
        margin-top: 70px;
    }

    .error{
        color: $error;
    }

    .success {
        color: $success;
    }

    .superwrapper {
        background-color: #EFF3F6;
        margin-top: 70px;   

        &.no-margin {
            margin: 0;
        }
    }

    .wrapper {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        padding: 30px 0;
        display: flex;

        &.no-flex {
            display: block;
            max-width: initial;
            padding: 0;
            // position: relative;
        }
    }

    .content{
        // padding-top: 20px;
        flex-grow: 1;
        // padding-left: 300px;
        // padding-right: 50px;
        // padding-bottom: 50px;

        .favourite-modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3000;
            
            .favourite-overlay {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6);
            }

            .favourite-content {
                width: 40%;
                // height: 200px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 4px;
                padding: 40px;

                h2 {
                    text-align: center;
                }

                img {
                    position: absolute;
                    top: 20px;
                    right: 10px;
                    width: 50px;
                    cursor: pointer;
                }

                .form-wrapper {
                    margin-top: 40px;

                    .input-holder {
                        padding: 0;
                        padding-bottom: 15px;

                        &:last-child {
                            padding: 0;
                        }
                    }

                    button {
                        margin-top: 10px;
                    }
                }
            }
        }

        .content-title{
            margin: 0;
            margin-bottom: 30px;
            font-family: "Nunito Sans";
            color: #354052;
            width: 100%;
            text-align: left;

            &.full-margin{
                margin-top:30px;
            }
        }

        .content-link {
            display: flex;
            margin-bottom: 30px;

            div {
                margin-right: 60px;
                font-size: 16px;
                font-weight: 400;
                color: #7F8FA4;
                cursor: pointer;

                &.active {
                    color: #354052;
                }
            }
        }

        .content-row{
            display: flex;
            align-items: flex-start;
        }

        .content-card {
            padding: 30px;
            background: #fff;
            border: 1px solid #E6EAEE;
            width: 100%;
            max-width: 700px;
            border-radius: 4px;
            cursor: pointer;

            .loading_holder {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 60px 0;

                .lds-ring {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                }
    
                .lds-ring div {
                    box-sizing: border-box;
                    display: block;
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    margin: 8px;
                    border: 8px solid #333;
                    border-radius: 50%;
                    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    border-color: #333 transparent transparent transparent;
                }
    
                .lds-ring div:nth-child(1) {
                    animation-delay: -0.45s;
                }
    
                .lds-ring div:nth-child(2) {
                    animation-delay: -0.3s;
                }
    
                .lds-ring div:nth-child(3) {
                    animation-delay: -0.15s;
                }
                
                @keyframes lds-ring {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
                  
            }

            &.half{
                width: 50%;
                max-width: auto;
                
                &:first-child{
                    margin-right: 15px;
                }

                &:last-child{
                    margin-right: 15px;
                }
            }

            &.smaller-padding{
                padding: 15px;
            }

            &.margin-bottom{
                margin-bottom: 30px;
            }

            h4 {
                color: #7F8FA4;
                font-size: 16px;
                font-weight: 400;
            }
            
            .card-holder {
                margin-top: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                column-gap: 40px;
                row-gap: 20px;

                div {
                    padding: 20px;
                    border: 1px solid #E6EAEE;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 8px;
                    -webkit-touch-callout: none;
                        -webkit-user-select: none; 
                        -khtml-user-select: none;
                        -moz-user-select: none;
                            -ms-user-select: none;
                                user-select: none;

                    img {
                        margin-bottom: 10px;
                        max-width: 70px;
                        max-height: 50px;
                    }

                    p {
                        color: #252631;
                        text-align: center;
                    }

                    &:hover {
                        border-color: #ff9233;
                    }

                    &.active {
                        border-color: #FF7800;
                    }
                }
            }
            
            .form-wrapper {
                // margin-top: 20px;
                width: 85%;
                
                &.full{
                    width: 100%;
                    margin-top: 0;
                }
                    
                .input-holder {
                    padding: 0 !important;
                    transition: opacity 0.5s;

                    &.hidden {
                        opacity: 0.5;
                    }

                    &.amount {
                        margin-top: 10px;
                        position: relative;

                        p {
                            position: absolute;
                            right: 0;
                            font-size: 13px;
                            top: 10px
                        }
                    }
                }

                button {
                    margin-top: 30px;
                    width: 100%;
                    background: #FF7800;
                    padding: 10px;
                    color: #fff;
                    font-size: 15px;
                    border: none;
                    outline: none;
                    font-weight: 600;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }
        }

        .content-form {
            padding: 30px;
            background: #fff;
            border: 1px solid #E6EAEE;
            margin-top: 10px;
            border-radius: 4px;
            width: 700px;

            .form-wrapper {
                    
                .input-holder {
                    padding: 0 !important;
                    transition: opacity 0.5s;

                    &.hidden {
                        opacity: 0.5;
                    }

                    &.amount {
                        margin-top: 10px;
                        position: relative;

                        p {
                            position: absolute;
                            right: 0;
                            font-size: 13px;
                            top: 10px
                        }
                    }
                }

                .amount-input {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    border: 1px solid #DFE3E9;
                    border-radius: 4px;
                    width: 100%;

                    h4 {
                        font-size: 20px;
                    }

                    input {
                        margin: 0;
                        border: none;
                        padding: 0;
                        margin-left: 10px;
                        outline: none;
                    }

                    p {
                        font-size: 18px;
                        color: #999;

                        &.value {
                            color: black;
                        }
                    }

                    &.btn {
                        cursor: pointer;
                    }
                }

                .amount-holder {
                    margin-top: 20px;
                    display: flex;

                    div {
                        background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
                        padding: 10px;
                        border: 1px solid #CED0DA;
                        margin-right: 20px;
                        border-radius: 4px;
                        font-size: 16px;
                        font-weight: 600;
                        cursor: pointer;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none; 
                        -khtml-user-select: none;
                        -moz-user-select: none;
                            -ms-user-select: none;
                                user-select: none;

                        &.active {
                            border-color: #FF7800;
                        }
                    }
                }

                .favourite-holder {
                    margin-top: 30px;
                    transition: opacity 0.5s;
                    text-align: left;
                    
                    .favourite-title {
                        display: flex;
                        justify-content: space-between;

                        img {
                            width: 20px;
                            cursor: pointer;
                        }
                    }

                    h4 {
                        color: #7F8FA4;
                        font-weight: 400;
                    }

                    .holder {
                        margin-top: 10px;
                        padding: 10px;
                        border: 1px solid #E6EAEE;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none; 
                        -khtml-user-select: none;
                        -moz-user-select: none;
                            -ms-user-select: none;
                                user-select: none;

                        img {
                            width: 18px;
                            margin-right: 30px;
                            height: 18px;
                        }
                    }

                    &.hidden {
                        opacity: 0.5;
                        // visibility: hidden;

                        div {
                            cursor: default;
                        }
                    }
                }

                button {
                    margin-top: 30px;
                    width: 100%;
                    background: #FF7800;
                    padding: 10px;
                    color: #fff;
                    font-size: 15px;
                    border: none;
                    outline: none;
                    font-weight: 600;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }

            &.custom {
                margin-bottom: 15px;

                .form-wrapper {
                    width: 85%;
                }

                .favourite-holder {
                    margin-top: 0;

                    .middle {
                        flex-grow: 1;

                        .title {
                            font-weight: 600;
                            color: #354052;
                        }

                        .subtitle {
                            color: #252631;
                            font-size: 14px;
                        }
                    }

                    .right {
                        font-size: 14px;
                    }
                }
            }
        }

        &.payment-method{
            .payment-title{
                text-align: left;
                margin-bottom: 20px;
                font-size: 20px;
            }

            .bank-transfer-wrap{
                .bank{
                    padding: 10px 15px;
                    border-bottom: 1px solid #E8ECEF;
                    text-align: left;
                    cursor: pointer;

                    &:last-child{
                        border-bottom: none;
                    }
                }
            }

            .eWallet-wrap{
                display: flex;
                flex-direction: column;

                .eWallet{
                    display: flex;
                    align-items: center;
                    padding: 15px 0;
                    cursor: pointer;
                    font-weight: bold;

                    img{
                        width: 50px;

                        margin-right: 30px;
                    }
                }
            }
            table{
                width: 100%;

                .add-method{
                    width: 20px;
                    cursor: pointer;
                }

                .card-detail{
                    display: flex;
                    align-items: center;

                    img{
                        margin-right: 10px;
                        width: 32px;
                    }

                }

                .align-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .default{
                        background-color: rgb(216, 218, 221);
                        border-radius: 4px;
                        color: #fff;
                        padding: 3px 15px;
                        margin-left: 10px;
                        margin-right: 16px;

                        &.active {
                            background-color:#F19C00;
                        }
                    }

                    button {
                        background: none;
                        border: none;
                        font-size: 14px;
                    }
                }
                
                .cards{
                    img{
                        &:first-child{
                            margin-right: 10px;
                        }
                    }
                }

                td{
                    padding: 10px 0;

                    &:first-child{
                        width: 20%;
                    }

                    &.align-left{
                        text-align: left;
                    }
    
                    &.align-right{
                        text-align: right;
                    }
                }
            }
        }

        &.home{
            text-align: left;
        }

        &.profile-settings{

            .form-wrapper {
                width: 100%;

                .input-holder {
                    margin-bottom: 20px;
                }

                .radio-select-holder {
                    padding: 10px 0;
                }

                button {
                    margin: 40px 0;
                    margin-bottom: 20px;
                    font-weight: bold;
                }
                
                .profile-picture-holder {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;

                    .profile-pic {
                        width: 100px;
                        height: 100px;
                        background-color: #fff;
                        background-image: url('../images/default_dp.png');
                        background-position: center;
                        background-size: cover;
                        border-radius: 100%;
                        overflow: hidden;
                        position: relative;

                        .edit-holder {
                            background: rgba($color: #000000, $alpha: 0.4);
                            color: #fff;
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            font-size: 15px;
                            padding-top: 3px;
                            padding-bottom: 5px;
                        }

                        input[type="file"] {
                            position: absolute;
                            bottom: 0;
                            cursor: pointer;
                            opacity: 0;
                            left: 0;
                            padding: 5px;
                        }
                    }
                }
            }

            .linked-account{
                display: flex;
                flex-direction: column;

                .link-block{
                    display: flex;
                    align-items: center;
                    div{
                        width: 50%;
                        border-radius: 4px;
                        background-color: #D0D0D0;
                        padding:10px 15px;
                        margin-bottom: 10px;
                        text-align: left;
                        font-size: 15px;
                        color: #fff;
                        display: flex;
                        align-items: center;

                        img{
                            margin-right: 10px;
                        }
                    }

                    &.active{
                        .google{
                            background-color: #F3575E;
                        }       
                    }

                    img{
                        margin-left: 10px;
                        width: 20px;
                    }

                    &:last-child{
                        div{
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        &.paip{
            .price{
                font-weight: bold;
            }

            .graph-wrap{
                margin-left: 20px;
            }


            .title-row{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                align-items:  flex-start;
                .content-title{
                    margin-bottom: 0px;
                    width: auto;
                }

                .switch-buttons-row{
                    display: flex;
                    align-items: flex-start;

                    .switch-buttons{
                        padding: 10px 40px;
                        margin-right: 15px;
                        background-color: #e1e0de;
                        color: #ff7800;
                        border-radius: 4px;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.active{
                            background-color: #FF7800;
                            color: #FFF;
                        }

                        
                    }
                }
            }

            .graph-data{
                display: flex;
                width: 100%;
                justify-content: space-around;

                .graph-data-block{
                    text-align: center;

                    .arrow-down {
                        width: 0; 
                        height: 0; 
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        
                        border-top: 5px solid #4ed033;
                        margin-right: 5px;
                    }

                    .arrow-up {
                        width: 0; 
                        height: 0; 
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        
                        border-bottom: 5px solid #fe4d4d;
                        margin-right: 5px;
                    }

                    .title{
                        color: #778ca2;
                        font-size:  14px;
                        font-weight: bold;
                    }

                    .total{
                        font-weight: bold;
                        font-size: 25px;
                    }

                    .stats{
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .pay-button{
                margin-top: 50px;
                width: 100%;
                background-color:#FF7800;
                padding: 10px 20px;
                text-align: center;
                color: #FFF;
                font-weight: bold;
                border-radius: 4px;
            }

            .top-data{
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                .row{
                    display: flex;
                    width: 100%;
                    
                    &:last-child{
                        .block{
                            margin-bottom: 0px;
                        }
                    }

                    .left{
                        text-align: left;
                        flex: 1;
                        font-weight: bold;
                        margin-bottom: 10px;
                        
                    }

                    .price{
                        font-size:  25px;
                        font-weight: bold;
                    }

                    .right{
                        text-align: right;
                        flex: 1;
                        margin-bottom: 10px;
                    }
                    
                    .block{
                        margin-bottom: 20px;
                        background-color: #fff;
                        flex:1;
                        border-radius: 4px;
                        padding: 20px;
                        text-align: left;

                        .icon{
                            img{
                                width: 15px;
                                margin-right: 5px;
                            }
                        }

                        h3{
                            margin-bottom: 10px;
                        }

                        .read-more-link{
                            color: #FF7800;
                            margin-bottom: 0px;
                            font-weight: bold;
                            margin-top: 10px;
                        }
                        
                        &:first-child{
                            margin-right: 10px;
                        }

                        &:last-child{
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        &.account{
            padding: 20px;
            background-color: #EAEAEA;
            height: 100vh;

            .account-wrapper{
                max-width: 900px;
                width: 100%;
                margin: 0 auto;
                background-color: white;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                padding: 15px;

                .profile-detail-wrap{
                    .profile-picture{
                        border-radius: 50px;
                        height: 100px;
                        width: 100px;
                        margin-bottom: 5px;
                    }
                    margin-bottom: 15px;
                }
                .account-settings{
                    background-color: white;
                    
                }
            }

        }

        &.pay{
            .tab-container{
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .tab-child{
                    width: 150px;
                    height: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    flex-wrap: wrap;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    position: relative;
                    cursor: pointer;

                    .notification{
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        color: #fff;
                        background-color: #FF7800;
                        top: -10px;
                        right: -10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .image-holder{
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        padding-bottom: 20px;
                        img{
                            // width: 60px;
                            height: 50px;
                        }
                    }
                    .tab-title{
                        width: 100%;
                        max-width: 110px;
                        text-align: left;
                        position: absolute;
                        bottom: 20px;
                        left: 20px;
                        color: #000;
                    }
                }

            }

            .type-row{
                display: flex;
                align-items: center;

                .logo-wrap{
                    background-color: #fff;
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    img{
                        width: 30px;
                        // height: 30px;
                    }
                }
            }

            .selection-wrap{
                margin-top: 30px;
                display: flex;


                .selection{
                    background-color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    width: 120px;
                    height: 120px;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid #E6EAEE;
                    cursor: pointer;
                    margin-right: 30px;
                    
                    &.active{
                        border: 1px solid #FF7800;
                    }

                    p{
                        width: 100%;
                        max-width: 80px;
                        text-align: center;
                        font-size: 14px;
                    }

                    img{
                        width: 40px;
                        margin-bottom: 5px;
                    }
                }
            }

            .partial-dropdown{
                margin-top: 30px;
                width: 100%;
                max-width: 800px;
                border: 1px solid #E6EAEE;
                border-radius: 5px;
                background-color: #fff;
                padding: 15px;
                box-sizing: border-box;
            }

            .payment-superwrap{
                display: flex;
                align-items: flex-start;

                .payment-choices-wrap{
                    border: 1px solid #E6EAEE;
                    background-color: #fff;
                    margin-right: 15px;
                    border-radius: 4px;
                    flex: 1;
                    padding: 30px;


                    .side-by-side {
                        
                        .input-holder {
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    .input-holder {
                        padding: 0;
                        margin-bottom: 20px;

                        &.no-margin {
                            margin: 0;
                        }
                    }

                    .payment-choices-row{
                        display: flex;
    
                        .payment-choice{
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            flex-wrap: wrap;
                            padding: 15px;
                            border-radius: 1px;
                            flex: 1;
                            margin-right: 15px;
                            border: 1px solid #E1E1E1;
                            cursor: pointer;

                            &:nth-child(2) {
                                img {
                                    width: 80px;
                                }
                            }

                            &.active{
                                border: 1px solid #FF7800;

                                p{
                                    color: #FF7800;
                                }
                            }
    
                            &:last-child{
                                margin-right: 0;
                            }
    
                            img{
                                width: 50px;
                                margin-bottom: 10px;
                            }
                            p{
                                width: 100%;
                            }
                        }
                    }

                    .payment-type-title{
                        text-align: left;
                        font-weight: bold;
                        // padding-left: 15px;
                        margin-top: 20px;
                        margin-bottom: 15px;
                        font-size: 18px;
                    }
                }

                .payment-data{
                    border: 1px solid #E6EAEE;
                    background-color: #fff;
                    padding: 25px 30px;
                    border-radius: 4px;
                    min-width: 30%;

                    .title{
                        font-weight: bold;
                        text-align: left;
                        margin-bottom: 20px;
                    }

                    td {
                        // padding-left: 0;
                        // padding-right: 0;
                    }

                    .no-padding td {
                        padding: 0;
                    }

                    &.prepaid {

                        tr:last-child {

                            td {
                                padding-top: 5px;
                            }
                        }

                        td {
                            // padding: 0;

                            &.phone-no {
                                padding-left: 30px;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            .compound-payment-form{
                .compound-pay-button{
                    background-color:#FF7800;
                    color: #fff;
                    padding: 10px 50px;
                    border-radius: 5px;
                    cursor: pointer;
                    p{
                        text-decoration: none !important;
                    }
                }
            }
        }

        &.bus{
            .bus-form-wrapper{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                .bus-form{
                    width: 100%;
                    background-color: #fff;
                    border: 1px solid #E6EAEE;
                    border-radius: 5px;
                    margin-bottom: 15px;

                    .border-right{
                        border-right: 1px solid #DFE3E9;
                    }

                    .form-wrapper{
                        display: flex;

                        .side-by-side{
                            // width: 40%;
                            flex: 1;
                            align-items: center;
                            
                            img.left-arrow{
                                width: 20px;
                                margin-top: 25px;
                            }
                        }

                        .checkbox-input{
                            margin-bottom: 5px;
                        }

                        .input-holder{
                            width: auto;
                            padding: 5px 10px;
                            
                            input{
                                font-size: 16px;
                                padding: 5px 10px;
                            }

                            .select-wrap{
                                option{
                                    font-size: 16px;
                                }
                            }

                            &.submit-holder{
                                align-items: flex-end;
                                input[type=submit]{
                                    width: auto;
                                    padding: 8px 30px;
                                }
                            }
                        }
                    }
                }
                .bus-trip-results-wrap{
                    display: flex;
                    width: 100%;
                    align-items: flex-start;
                    .bus-trip-results{
                        width: 100%;
                        flex: 1;
                        background-color: #fff;
                        border: 1px solid #E6EAEE;
                        padding: 15px;
                        border-radius: 5px;
                        margin-right: 15px;
                        .depart-results{
                            &.margin-top{
                                margin-top: 30px;
                            }
    
                            .bus-trip-select{
                                color: #FF7800;
                                font-weight: bold;
                            }
                        }
    
                        .result-detail{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .trip-detail{
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 15px;
                                .title{
                                    font-weight: bold;
                                    font-size: 18px;
                                    margin-bottom: 15px;
                                }
        
                                .route{
                                    display: flex;
                                    width: 100%;
                                    margin-bottom: 5px;
                                    align-items: center;
                                    
                                    img{
                                        margin: 0 5px;
                                        height: 16px;
        
                                    }
                                }
        
                                .trip-date{
                                    color: #7F8FA4;
                                    font-size: 14px;
                                }
                            }
    
                            .trip-avalability{
                                color: #7F8FA4;
                            }
                        }
                    }

                    .bus-trip-summary{
                        background-color: #fff;
                        border: 1px solid #E6EAEE;
                        padding: 15px;
                        border-radius: 5px;
                        text-align: left;

                        .trip-detail-text {
                            padding-left: 30px;
                            opacity: 0.5;
                            font-size: 14px;
                        }

                        .title{
                            font-weight: bold;
                            text-align: left;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        &.road-tax{

            .payment-data{
                .title{
                    padding-left: 15px;
                }

                .payment-table{
                    td{
                        padding: 10px 15px;
                    }
                }
            }

            .submit-button{
                background-color:#FF7800;
                border-radius: 5px;
                color: #fff;
                padding: 10px;
                margin-top: 30px;
                font-weight: bold;
            }
        }
    }

    // .land-tax{
    //     border: 1px solid #E6EAEE;
    //     border-radius: 5px;
    //     background-color: #fff;
    //     padding: 15px;
    //     padding-right: 100px;
    // }

    .land-payment-form{
        .show-payment-details{
            text-align: left;
            color: #FF7800;
            padding-left: 15px;
            margin-bottom:10px;
            cursor: pointer;

            &:hover{
                text-decoration: underline;
            }
        }

        .radio-select-holder {
            padding: 15px;
            margin-bottom: 0;
        }

        .payment-details-wrap{
            width: 100%;
            padding: 15px;
            box-sizing: border-box;

            .bold-text {
                
                td {
                    font-weight: 600;
                }
            }

            td {
                text-align: left;
                font-size: 16px;
                padding-bottom: 15px;

                &:first-child {
                    padding-right: 100px;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}
