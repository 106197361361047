@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

body {
  margin: 0;
  /* font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
  /* font-family: "Source Sans Pro"; */
}

.App {
  height: 100vh;
}

.pointer {
  cursor: pointer;
}

.opacity {
  opacity: 0.5;
}

.card {
  transition: all .25s ease-in-out;
}

.interactive:hover {
  border-color: #137eff !important;
}

.menu {
  display: flex;
    flex-wrap: nowrap;
    padding: 0 0.5rem;
    line-height: 2.25rem;
    position: relative;
}

.active .menu {
  color: #137eff !important;
}

.lolo {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3000;
}

.lolo iframe {
  height: 100%;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-overlay-holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3000;
}

.loading-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #333;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.profilepic {
  width: 100px;
  height: 100px;
  background-color: #fff;
  background-image: url('./assets/images/default_dp.png');
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.edit-holder {
  background: rgba(0, 0, 0, .4);
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 15px;
  font-size: 13px;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 5px;
}

.file {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  left: 0;
  padding: 5px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2em;
    row-gap: 2em
}

.separator {
    font-size: 18px;
    font-weight: bold;
    color: #ccc;
}

.react-datepicker__current-month {
  text-align: center;
}
